import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { baseURl } from "../../utils";
import image from "../../image/slider2.jpg";

export const DoctorRegister = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    category_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    try {
      const endpoint = `${baseURl}/auth/doctor/register`;
      const data = {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        password: formData.password,
        category_id: formData.category_id,
      };

      const response = await axios.post(endpoint, data);

      //console.log("API Response:", response);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Doctor registered successfully! Please enter the OTP sent to your email",
        input: "text",
        inputPlaceholder: "Enter OTP",
        showCancelButton: true,
        confirmButtonText: "Verify OTP",
        bodydisable: false,
        preConfirm: (otp) => {
          if (!otp) {
            Swal.showValidationMessage("Please enter the OTP");
          } else {
            return verifyOtp(otp, formData.mobile);
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Verified",
            text: "OTP verified successfully!",
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            icon: "info",
            title: "Cancelled",
            text: "OTP verification cancelled.",
          });
        }
      });

      setFormData({
        name: "",
        mobile: "",
        email: "",
        password: "",
        confirm_password: "",
        category_id: "",
      });
      setErrors({});
    } catch (error) {
      console.error("Error registering doctor:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to register doctor. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateForm = (formData) => {
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = "Doctor name is required";
    }

    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number should be 10 digits";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    } else if (formData.password.trim().length < 8) {
      errors.password = "Password should be at least 8 characters";
    }

    if (formData.password !== formData.confirm_password) {
      errors.confirm_password = "Passwords do not match";
    }

    return errors;
  };

  const verifyOtp = async (otp, mobile) => {
    try {
      const response = await axios.post(`${baseURl}/auth/doctor/verify-otp`, {
        otp,
        mobile,
      });
      return response.data.success;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to verify OTP. Please try again.",
      });
      return false;
    }
  };

  return (
    <div
      className="row justify-content-center py-5 mx-0"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="col-xl-4 col-lg-5 col-md-7 bg-light">
        <h1 className="mt-4 fs-2 text-center text-website">
          Doctor Registration
        </h1>
        <form
          onSubmit={handleSubmit}
          className="justify-content-center py-lg-4 w-100"
        >
          <div className="d-flex flex-column px-2">
            <div className="mb-3">
              <label className="pb-1">Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Doctor Name"
                required
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="pb-1">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  placeholder="9876543210"
                  maxLength={10}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  required
                />
                {errors.mobile && <p className="error">{errors.mobile}</p>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="pb-1">Category</label>
                <select
                  className="form-control"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Category</option>
                  <option value="1">Category 1</option>
                  <option value="2">Category 2</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label className="pb-1">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="abc@gmail.com"
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="pb-1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="*********"
                  required
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
              <div className="col-md-6 mb-3">
                <label className="pb-1">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleInputChange}
                  placeholder="*********"
                  required
                />
                {errors.confirm_password && (
                  <p className="error">{errors.confirm_password}</p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success mt-3"
              disabled={loading}
            >
              {loading ? "Registering..." : "Register"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
