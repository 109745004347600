import { AUTH_TOKEN, BASE_URL } from "../utils";

const authService = {};
authService.login = function (reqeustParam) {
  return fetch(`${BASE_URL}/auth/login`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.ragister = function (reqeustParam) {
  return fetch(`${BASE_URL}/doctor/stepOne`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.ragister3 = function (reqeustParam) {
  return fetch(`${BASE_URL}/doctor/stepThree`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
    body: JSON.stringify(reqeustParam),
  });
};

authService.fatchToken = function (reqeustParam) {
  return fetch(`${BASE_URL}/fatch/token`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.fatchReview = function (reqeustParam) {
  return fetch(`${BASE_URL}/get-review`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.doctorList = function (reqeustParam) {
  return fetch(`${BASE_URL}/get-doctors`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.doctorNoticeListHome = function (reqeustParam) {
  return fetch(`${BASE_URL}/get-doctor-notice`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.categories = function (reqeustParam) {
  return fetch(`${BASE_URL}/get-category`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

authService.getArticals = function (reqeustParam) {
  return fetch(`${BASE_URL}/get-articals`, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqeustParam),
  });
};

export default authService;
