export const AUTH_KEY = "userDetails";
export const TOKEN_KEY = "token";
export const USER_DETAILS =
  localStorage.getItem(AUTH_KEY) && JSON.parse(localStorage.getItem(AUTH_KEY));
export const AUTH_TOKEN = localStorage.getItem(TOKEN_KEY) || "";

export const baseURl = "https://uat.mkite.in/backend/public/api";
export const BASE_URL = "https://uat.mkite.in/backend/public/api";

// export const baseURl="https://mkite.in/backend/public/api";
// export const BASE_URL="https://mkite.in/backend/public/api";

// export const baseURl = "http://127.0.0.1:8000/api";
// export const BASE_URL = "http://127.0.0.1:8000/api";