import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import axios from "axios";
import { AUTH_TOKEN, baseURl } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import Header from "./Header";
import { FeeTypeModel } from "./components/FeeTypeModel";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

export const FeeType = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("Add");
  const [list, setList] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    amount: "",
    is_active: 1,
  });
  const [permissions, setPermissions] = useState({
    add: 0,
    edit: 0,
    delete: 0,
  });
  const userPer = JSON.parse(localStorage.getItem('role_permissions') || "[]");

  

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fees_id: formData.fees_id,
      title: formData.title,
      amount: formData.amount,
      is_active: formData.is_active,
    };
    setIsLoading(true);
    axios
      .post(`${baseURl}/doctor/fees/add`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        const setData = {
          title: "",
          amount: "",
          is_active: 1,
        };
        setFormData(setData);
        setIsLoading(false);
        fatchList();
        setIsModel(!isModel);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        setIsLoading(false);
      });
  };

  const fatchList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/fees/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const editFees = (el) => {
    const setData = {
      fees_id: el.id,
      title: el.title,
      amount: el.amount,
      is_active: el.is_active,
    };
    setFormData(setData);
    setMode("Edit");
    setIsModel(!isModel);
  };

  const deleteFees = async (fees_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/fees/delete`,
        { fees_id: fees_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        fatchList();
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const modelToggle = () => {
    setIsModel(!isModel);
    setMode("Add");
    const setData = {
      title: "",
      amount: "",
      is_active: 1,
    };
    setFormData(setData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fatchList();
    const addPermission = userPer.some((perm) => perm.id === 36);
    const editPermission = userPer.some((perm) => perm.id === 37);
    const deletePermission = userPer.some((perm) => perm.id === 38);
    setPermissions({ add: addPermission, edit: editPermission, delete: deletePermission });
  
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex justify-content-center">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Fees List</h4>
                  {permissions.add > 0 && (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={modelToggle}
                    >
                      Add Fees
                    </button>
                  )}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={item.id}>
                          <td>{item.title}</td>
                          <td>{item.amount}</td>
                          <td>{item.is_active == 1 ? "Active" : "Inactive"}</td>
                          <td>
                            <div className="d-flex">
                            {permissions.edit > 0 && (
                                <button
                                  onClick={() => editFees(item)}
                                  className="btn btn-warning btn-sm ml-2"
                                  title="Edit Fee Type"
                                >
                                  <FaRegEdit />
                                </button>
                              )}
                              &nbsp;
                              {permissions.delete > 0 && (
                                <button
                                  onClick={() => deleteFees(item.id)}
                                  className="btn btn-danger btn-sm mr-2"
                                  title="Delete Fee Type"
                                >
                                  <MdDeleteOutline />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeeTypeModel
        show={isModel}
        handleSubmit={handleSubmit}
        formData={formData}
        handleInputChange={handleInputChange}
        modelToggle={modelToggle}
        isLoading={isLoading}
        style={{ width: "450px" }}
        mode={mode}
      />
    </div>
  );
};

const hasPermission = (user, requiredPermission) => {
  return user.permissions.includes(requiredPermission);
};
