import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {
  BiBell,
  BiClinic,
  BiDollar,
  BiHome,
  BiListCheck,
  BiListUl,
  BiLogOut,
  BiPlus,
  BiTime,
  BiUser,
  BiCog,
  BiRightArrowAlt,
  BiDownArrowAlt,
  BiWallet,
} from "react-icons/bi";
import logo from "../../image/logo.png";
import { AUTH_KEY, AUTH_TOKEN, TOKEN_KEY, baseURl } from "../../utils";

const MenuIcon = ({ icon }) => {
  const iconT =
    icon === "BiPlus" ? (
      <BiPlus size={25} className="me-1" />
    ) : icon === "BiBell" ? (
      <BiBell size={25} className="me-1" />
    ) : icon === "BiClinic" ? (
      <BiClinic size={25} className="me-1" />
    ) : icon === "BiDollar" ? (
      <BiDollar size={25} className="me-1" />
    ) : icon === "BiHome" ? (
      <BiHome size={25} className="me-1" />
    ) : icon === "BiListCheck" ? (
      <BiListCheck size={25} className="me-1" />
    ) : icon === "BiTime" ? (
      <BiTime size={25} className="me-1" />
    ) : icon === "BiUser" ? (
      <BiUser size={25} className="me-1" />
    ) : icon === "BiCog" ? ( // Add BiCog for settings icon
      <BiCog size={25} className="me-1" />
    ) : icon === "BiWallet" ? ( // Add BiCog for settings icon
      <BiWallet size={25} className="me-1" />
    ) : (
      <BiListUl size={25} className="me-1" />
    );

  return iconT;
};

export const Dashboard = () => {
  const [menu, setMenu] = useState(
    JSON.parse(localStorage.getItem("menu") || "[]")
  );

  const [visibleSubmenu, setVisibleSubmenu] = useState(null);
  useEffect(() => {
    if (!AUTH_TOKEN) {
      window.location.href = "../";
    }
    if (menu.length == 0) {
      getPermission();
    }
  }, []);

  const logout = async () => {
    try {
      await axios.post(
        `${baseURl}/doctor/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching the API:", error);
    }

    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem("role_permissions");
    localStorage.removeItem("menu");
    window.location.href = "../";
  };

  const getPermission = async () => {
    try {
      const response = await axios.post(
        `${baseURl}/role/permissions`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      const permissions = response.data.data.permissions;
      const role_permissions = response.data.data.role_permissions;
      localStorage.setItem(
        "role_permissions",
        JSON.stringify(role_permissions)
      );
      localStorage.setItem("menu", JSON.stringify(permissions));
      setMenu(permissions);
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleMenuClick = (key) => {
    setVisibleSubmenu(visibleSubmenu === key ? null : key);
  };
  const renderMenuItem = (item, index) => {
    const menuItemStyle = {
      color: "#52a19a",
      backgroundColor: "white",
      cursor: "pointer",
    };

    return (
      <div key={item.key}>
        {item.submenu && item.submenu.length ? (
          <>
            <div
              className="list-group-item list-group-item-action d-flex justify-content-between"
              style={menuItemStyle}
              onClick={() => item.submenu && handleMenuClick(item.key)}
            >
              <div>
                <MenuIcon icon={item.icon} /> {item.title}{" "}
              </div>
              <div>
                {visibleSubmenu != item.key ? (
                  <BiRightArrowAlt />
                ) : (
                  <BiDownArrowAlt />
                )}
              </div>
            </div>

            {item.submenu.map((subItem) => (
              <NavLink
                to={subItem.path}
                key={subItem.key}
                className="list-group-item list-group-item-action"
                hidden={visibleSubmenu != item.key}
                onClick={() => handleMenuClick(item.key)}
              >
                <MenuIcon icon={subItem.icon} /> {subItem.title}
              </NavLink>
            ))}
          </>
        ) : (
          <NavLink
            to={item.path}
            key={item.key}
            className="list-group-item list-group-item-action"
          >
            <MenuIcon icon={item.icon} /> {item.title}
          </NavLink>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="d-none d-md-block"
        style={{
          borderLeft: "1px solid gray",
          background: "white",
          width: "240px",
        }}
      >
        <div className="text-center">
          <div className="list-group dashboard-menu w-100 text-start">
            <NavLink
              to="/"
              className="w-100 justify-content-center text-center"
            >
              <img className="py-2" src={logo} style={{ width: "130px" }} />
            </NavLink>
            <NavLink className="text-decoration-none ">
              {menu.length > 0 &&
                menu.map((item, index) => renderMenuItem(item, index))}
            </NavLink>
            <NavLink
              to="/"
              className="list-group-item list-group-item-action py-3"
              onClick={() => logout()}
              style={{
                color: "#52a19a",
                backgroundColor: "white",
              }}
            >
              <BiLogOut size={25} className="me-1" /> Logout
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};
