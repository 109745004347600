import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import axios from "axios";

export const Wallet = () => {
  const [list, setList] = useState([]);
  const [wallet, setWallet] = useState(900);

  const fetchHistory = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/history`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setList(response.data.data.history);
      setWallet(response.data.data.wallet);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);
  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="alert alert-warning pb-2 pt-3">
              <h4>Wallet Amount - {wallet}</h4>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Transaction History</h4>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Merchant Id</th>
                        <th>Merchant Transaction Id</th>
                        <th>Transaction Id</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item) => (
                        <tr key={item.id}>
                          <td>{item.merchantId}</td>
                          <td>{item.merchantTransactionId}</td>
                          <td>{item.transactionId}</td>
                          <td>{item.amount}</td>
                          <td>{item.status == 1 ? "Success" : "Faild"}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
