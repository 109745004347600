import React, { useEffect, useState } from "react";
import authService from "../services/authService";
import Swal from "sweetalert2";
import { Button, Card, Modal } from "react-bootstrap";
import { IoIosCall } from "react-icons/io";
import { PATIENT_URLS } from "../utils/constent";
import { TokenCreate } from "../views/admin/components/TokenCreate";

export const DoctorAssociated = () => {
  const [doctors, setDoctors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [istrue, setIsTrue] = useState(false);
  const [doctorNotice, setDoctorNotice] = useState([]);

  const handelmodalcontain = () => {
    setIsTrue(!istrue);
  };

  const getDoctors = async (category_id = null) => {
    const reqeustParam = { category_id: category_id };
    const resp = authService.doctorList(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setDoctors(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const getCategory = async () => {
    const reqeustParam = { is_active: 1 };
    const resp = authService.categories(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setCategories(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const getDoctorNotice = async (doctor_id = null) => {
    const reqeustParam = { doctor_id: doctor_id };
    const resp = authService.doctorNoticeListHome(reqeustParam);
    resp
      .then(async (res) => {
        const resData = await res.json();
        if (resData.success === true) {
          const data = resData.data;
          setDoctorNotice(data);
        } else {
          Swal.fire({
            icon: "error",
            title: `${resData.message}`,
            text: `Time - `,
          });
        }
      })
      .catch((err) => {});
  };

  const categoryChange = async (e) => {
    const category_id = e.target.value;
    getDoctors(category_id);
  };

  const refreshData = () => {
    setTimeout(() => {
      getDoctors();
      refreshData();
    }, 60000);
  };

  useEffect(() => {
    getDoctors();
    getCategory();
    refreshData();
    handelmodalcontain();
  }, []);

  const handleBookNowClick = (doctor) => {
    setSelectedDoctor(doctor);
    setIsTrue(true);
    setShowModal(true);
  };

  const handlePopupMsg = (doctor_id) => {
    setShowMsgModal(true);
    getDoctorNotice(doctor_id);
  };

  const handleCloseModal = () => {
    setSelectedDoctor(null);
    setShowModal(false);
    setIsTrue(false);
    setShowMsgModal(false);
  };

  return (
    <div>
      <div className="py-5" style={{ background: "#00a19b" }}>
        <h2 className="text-center text-white pb-4">Doctor Associated</h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="text-right mb-3">
                <select className="form-control" onChange={categoryChange}>
                  <option value="">Select Category</option>
                  {categories.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            {doctors.map((doctor, index) => (
              <div className="col-lg-4" key={index}>
                <Card className="mb-4">
                  <div className="row align-items-center">
                    <div className="col-4 hover">
                      {doctor.image ? (
                        <img
                          src={doctor.image}
                          alt="doctor"
                          className="w-100"
                        />
                      ) : (
                        <img
                          src="../doct.png"
                          alt="default doctor"
                          className="w-100 ps-2"
                        />
                      )}
                    </div>
                    <div className="col-8 py-2">
                      <h5 className="py-1 my-0">{doctor.name}</h5>

                      <h6 className="py-0 my-0">
                        {doctor?.location?.registration_hospital_name}
                      </h6>
                      <div className="d-flex pt-1 pe-3 justify-content-between">
                        {doctor?.location?.mobile ? (
                          <p className="text-dark">
                            <IoIosCall /> {doctor?.location?.mobile}
                          </p>
                        ) : (
                          ""
                        )}

                        <p className="text-success small m-0 p-0">
                          {" "}
                          OPD Timing :{doctor?.shifts[0]?.from}
                        </p>
                        <p className="text-success small m-0 p-0">
                          {" "}
                          Fees :{doctor?.shifts[0]?.fees}
                        </p>
                      </div>
                      <p children="text-white m-0 p-0">
                        {doctor?.location?.category?.name}
                      </p>
                      {doctor?.online_booking == 1 ? (
                        <Button
                          className="btn-sm my-1  m-0 py-0"
                          style={{ backgroundColor: "#00a19a" }}
                          onClick={() => handleBookNowClick(doctor)}
                        >
                          Get Token
                        </Button>
                      ) : (
                        <Button
                          className="btn-sm my-1  m-0 py-0"
                          style={{ backgroundColor: "#00a19a" }}
                          onClick={() => handlePopupMsg(doctor.id)}
                        >
                          Get Token
                        </Button>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <div className="small">
              Book Appointment with {selectedDoctor?.name}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {istrue ? (
            <div>
              <p>
                <span className="text-danger fs-5">*</span> एक टोकन नंबर केवल एक
                ही मरीज के लिए मान्य है यदि आप दो मरीजों को दिखाना चाहते है तो
                कृपया दोनो के लिए अलग टोकन प्राप्त करें{" "}
              </p>
              <p>
                <span className="text-danger fs-5">*</span> बुकिंग करने से पहले
                सुनिश्चित कर ले कि आपने या आपके परिचित ने आपसे पहले टोकन बुकिंग
                तो नही कर ली, एक ही मरीज के लिए एक ही अथवा एक से अधिक व्यक्तियों
                द्वारा एक ही नाम/ मोबाइल नंबर से अथवा अलग अलग नामों/मोबाइल नंबर
                से टोकन प्राप्त करने पर सेवा शुल्क वापिस नही किया जाएगा,
              </p>
              <p>
                <span className="text-danger fs-5">*</span> बुकिंग करते
                समय/पहले/बाद में यदि आपको आपके appointment से संबंधित कोई भी
                जानकारी अगर प्रदान की जा रही हैं तो वह सिर्फ अनुमानित है और उसमे
                बदलाव संभव है।
              </p>
              <p>
                <span className="text-danger fs-5">*</span> यह सिर्फ अपॉइंटमेंट
                के लिए टोकन की बुकिंग है ना कि अपॉइंटमेंट की बुकिंग अतः इसके लिए
                दिया गया शुल्क डॉक्टर की फीस से पूर्णत अलग है{" "}
              </p>
              <p>
                <span className="text-danger fs-5">*</span> टोकन बुकिंग करने के
                बाद यदि किसी कारणवश डॉक्टर अपनी सेवा देने (पूरे दिन के लिए)में
                असमर्थ होता है तो उसके लिए mkite किसी भी प्रकार से जिम्मेदार
                नहीं होगा
              </p>
              <p>
                <span className="text-danger fs-5">*</span> डॉक्टर द्वारा यदि
                किसी कारण वश सेवा देने में असमर्थ होने की दशा में आपके द्वारा
                दिया गया शुल्क वापिस किया जाएगा
              </p>
              <p>
                <span className="text-danger fs-5">*</span> डॉक्टर द्वारा देरी
                से सेवा आरंभ करने/मरीज के द्वारा देरी से पहुंचने/ना पहुंचने/नंबर
                निकल जाने अथवा डॉक्टर की सेवा ना लेने/बाद में लेने की दशा में
                सेवा शुल्क वापिस नही किया जाएगा
              </p>
              <Button className="btn-dark" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button onClick={handelmodalcontain} className="ms-3 btn-info">
                Continue
              </Button>
            </div>
          ) : (
            <TokenCreate
              doctor_id={selectedDoctor?.id}
              urls={PATIENT_URLS}
              is_last={false}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showMsgModal} onHide={handleCloseModal}>
        <Modal.Body>
          <p>
            Online token booking is not available for now. Please contact the
            clinic directly to get token.
          </p>
          {doctorNotice.map((item, index) => (
            <p
              className={`p-1 h6 ${
                index % 2 == 0 ? "bg-danger text-white" : "bg-warning"
              }`}
              key={item.id}
            >
              {item?.message}
            </p>
          ))}
          <div className="mt-3 d-flex justify-content-end">
            <Button className="btn-dark" onClick={handleCloseModal}>
              close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <hr className="my-0" />
    </div>
  );
};
