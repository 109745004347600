import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { Dashboard } from "./Dashboard";
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { IoKeyOutline } from "react-icons/io5";

const Role = () => {
  const [roles, setRoles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [newRole, setNewRole] = useState({ name: "", is_active: false });
  const [permissions, setPermissions] = useState({
    add: 0,
    edit: 0,
    delete: 0,
    assign: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles();
    if (userPer) {
      // console.log(userPer);
      const addPermission = userPer.some((perm) => perm.id === 41);
      const editPermission = userPer.some((perm) => perm.id === 42);
      const deletePermission = userPer.some((perm) => perm.id === 43);
      const assignPermission = userPer.some((perm) => perm.id === 44);
      // console.log(addPermission);
      setPermissions({
        add: addPermission,
        edit: editPermission,
        delete: deletePermission,
        assign: assignPermission,
      });
    }
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRole({ ...newRole, [name]: value });
  };

  const handleStatusChange = () => {
    setNewRole({ ...newRole, is_active: !newRole.is_active });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      await handleEditRole();
    } else {
      await handleAddRole();
    }
  };

  const handleAddRole = async () => {
    try {
      await axios.post(`${BASE_URL}/role/add`, newRole, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Role added successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      setShowModal(false);
      setNewRole({ name: "", is_active: false });
      fetchRoles();
    } catch (error) {
      console.error("Error adding role:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to add role!",
        text: error.response?.data?.message || error.message,
      });
    }
  };

  const handleEditRole = async () => {
    newRole.role_id = selectedRole.id;
    try {
      await axios.post(`${BASE_URL}/role/update`, newRole, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Role updated successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      setShowModal(false);
      setNewRole({ name: "", is_active: false });
      fetchRoles();
    } catch (error) {
      console.error("Error editing role:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to edit role!",
        text: error.response?.data?.message || error.message,
      });
    }
  };

  const handleDeleteRole = async (id) => {
    try {
      await axios.post(
        `${BASE_URL}/role/delete`,
        { role_id: id },
        {
          headers: {
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Role deleted successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      fetchRoles();
    } catch (error) {
      console.error("Error deleting role:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to delete role!",
        text: error.response?.data?.message || error.message,
      });
    }
  };

  const openEditModal = (role) => {
    setIsEditMode(true);
    setSelectedRole(role);
    setNewRole({ name: role.name, is_active: role.is_active });
    setShowModal(true);
  };

  const openAddModal = () => {
    setIsEditMode(false);
    setSelectedRole(null);
    setNewRole({ name: "", is_active: false });
    setShowModal(true);
  };

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex justify-content-center">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Roles List</h4>
                  {permissions.add > 0 && (
                    <Button variant="primary" onClick={openAddModal}>
                      Add Role
                    </Button>
                  )}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((role) => (
                        <tr key={role.id}>
                          <td className="py-2">{role.name}</td>
                          <td className="py-2">
                            <span
                              className="py-0 pb-1 px-2 rounded"
                              style={{
                                backgroundColor: role.is_active
                                  ? "green"
                                  : "red",
                                color: "white",
                              }}
                            >
                              {role.is_active ? "Active" : "Inactive"}
                            </span>
                          </td>
                          <td className="text-center">
                            <div className="d-flex">
                              {permissions.edit > 0 && (
                                <button
                                  className="btn btn-info ml-2 ms-2 btn-sm"
                                  onClick={() =>
                                    navigate(
                                      `/admin/promisses/${role.id}?name=${role.name}`
                                    )
                                  }
                                  title="Set permission"
                                >
                                  <IoKeyOutline />
                                </button>
                              )}
                              {permissions.delete > 0 && (
                                <button
                                  className="btn btn-warning me-2 ms-2 btn-sm"
                                  onClick={() => openEditModal(role)}
                                  title="edit"
                                >
                                  <FaRegEdit />
                                </button>
                              )}
                              &nbsp;
                              {permissions.assign > 0 && (
                                <button
                                  className="btn btn-danger btn-sm me-2"
                                  onClick={() => handleDeleteRole(role.id)}
                                  title="delete"
                                >
                                  <MdDeleteOutline />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? "Edit Role" : "Add Role"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formRoleName" className="mb-4">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newRole.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formRoleStatus" className="mb-2">
              <Form.Check
                style={{ width: "2.5em" }}
                type="switch"
                id="custom-switch"
                label="Status"
                name="is_active"
                checked={newRole.is_active}
                onChange={handleStatusChange}
              />
            </Form.Group>
            <div className="col-md-12 mb-3 text-end">
              <Button className="text-end" variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Role;
