import "./App.css";
import React, { useEffect, useState } from "react";
import { AdminRoute } from "./routes/Admin";
import { UserRoute } from "./routes/Users";
import { AUTH_TOKEN } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthRoute } from "./routes/Auth";
import { Header } from "./components/Header";
import Footer from "./components/Footer";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const path = pathname.substring(1, 6);

  useEffect(() => {
    if (AUTH_TOKEN.length > 0) {
      setIsLogin(true);
    }
    if (pathname == "/login" && isLogin === true) {
      navigate("admin/dashboard");
    }
    if(path == "admin" && isLogin === false && AUTH_TOKEN.length == 0)
      {
          window.location.href = "../";
      }
  });
  return (
    <div>
      {path != "admin" ? <Header isLogin={isLogin} /> : ""}
      {isLogin ? <AdminRoute /> : <AuthRoute />}
      <UserRoute />
      {path != "admin" ? <Footer /> : ""}
    </div>
  );
}

export default App;
