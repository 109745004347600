import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import { AUTH_TOKEN, USER_DETAILS, baseURl } from "../../utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import FeeModel from "./components/FeeModel";
import { FaRegEdit } from "react-icons/fa";

export const ReportData = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [shifts, setShift] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tokenid, setSetTokenId] = useState(false);
  const [fees, setFees] = useState(0);
  const [formData, setFormData] = useState({
    fromDate: "",
    toDate: "",
  });
  const [permissions, setPermissions] = useState({
    edit: 0,
    fee: 0,
  });
  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleShowModal = (token_id, fees) => {
    setSetTokenId(token_id);
    setFees(fees);
    setShowModal(true); // Show modal
  };
  const handleCloseModal = () => setShowModal(false); // Close modal

  const handleSubmit = (e) => {
    e.preventDefault();
    fatchList();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fatchList = async () => {
    try {
      // Make the API call
      setIsLoading(true);
      formData.shift_id =
        localStorage.getItem("shift_id") ?? USER_DETAILS.active_shift_id;
      const response = await axios.post(`${baseURl}/doctor/report`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      });

      if (response.status === 200) {
        setList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const getShiftList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/listShift`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setShift(response.data.data);
        setIsLoading(false);
        if (response.data.data.length == 1) {
          localStorage.setItem("shift_id", response.data.data[0].id);
        }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching the API:", error);
    }
  };

  const refreshData = () => {
    setTimeout(() => {
      fatchList();
      refreshData();
    }, 60000);
  };

  const reSetForm = () => {
    var today = new Date();
    var day =
      today.getDate().toString().length == 1
        ? "0" + today.getDate()
        : today.getDate();
    var month =
      (today.getMonth() + 1).length == 0
        ? today.getMonth() + 1
        : "0" + (today.getMonth() + 1);
    var todate = today.getFullYear() + "-" + month + "-" + day;
    setFormData({
      ...formData,
      ["fromDate"]: todate,
      ["toDate"]: todate,
    });
    fatchList();
  };

  useEffect(() => {
    getShiftList();
    fatchList();
    reSetForm();
    if (userPer) {
      // console.log(userPer);
   
      const editPermission = userPer.some((perm) => perm.id === 42);
      const feePermission = userPer.some((perm) => perm.id === 43);
     
      // console.log(addPermission);
      setPermissions({
        edit: editPermission,
        fee: feePermission,
      });
    }
  }, []);

  const editToken = (token_id) => {
    navigate(`../admin/token/edit/${token_id}`);
  };

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "60px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container my-3">
            <hr className="d-block d-lg-none" />
            <div className="app-greeting px-4 pt-lg-5">
              <div className="d-flex flex-row justify-content-between">
                <h4 className="nameHeading">
                  <span className="ng-star-inserted">
                    Dr. {USER_DETAILS.name}
                  </span>
                  {/* <span className="ms-3" style={{ fontSize: "small" }}>
                    Time: 02:32 AM
                  </span> */}
                </h4>
                <div>
                  <div className="avatr">
                    <div className="bg-secondary rounded-circle px-3">
                      <span className="fs-2 text-uppercase">
                        {USER_DETAILS.name.slice(0, 1)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="reportContainer mt-5 ps-3 pe-4 pe-2">
              <div className="">
                <form className="row mb-3" onSubmit={handleSubmit}>
                  <div className="col-md-3 form-group">
                    <label htmlFor="fromDate" className="k-label">
                      From:
                    </label>
                    <input
                      type="date"
                      name="fromDate"
                      className="form-control"
                      value={formData.fromDate}
                      onChange={(e) => handleInputChange(e)}
                      pattern="dd-mm-yyyy"
                    />
                  </div>
                  <div className="col-md-3 form-group">
                    <label htmlFor="toDate" className="k-label">
                      To:
                    </label>
                    <input
                      type="date"
                      name="toDate"
                      className="form-control"
                      value={formData.toDate}
                      onChange={(e) => handleInputChange(e)}
                      pattern="dd-mm-yyyy"
                    />
                  </div>
                  {shifts.length > 1 && (
                    <div className="col-md-2 form-group">
                      <label htmlFor="Shift" className="k-label">
                        Select Shift
                      </label>
                      <select
                        className="form-control"
                        placeholder="Shift Name"
                        onChange={(e) => handleInputChange(e)}
                        name="shift_id"
                        value={formData.shift_id}
                      >
                        {shifts.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="btn btn-primary px-lg-4 mt-4 me-2"
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}{" "}
                      Submit
                    </button>
                    <button
                      onClick={() => reSetForm()}
                      type="reset"
                      className="btn btn-info px-lg-4 mt-4"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
              <hr />
              <div className="">
                <div className="my-4">
                  <h4>Patient List</h4>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-3 w-100">
                      <thead>
                        <tr>
                          <th>S.N.</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Token</th>
                          <th>Time</th>
                          <th>Fee</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item?.patient?.name ?? "Auto generate"}</td>
                            <td>{item?.patient?.mobile ?? "NO"}</td>
                            <td>{item?.token_number}</td>
                            <td>{item.expected_time}</td>
                            <td>{item.is_paid}</td>
                            <td>{item.is_type}</td>
                            <td className="d-flex">
                              {!item.is_online && (
                                permissions.edit >0 &&(
                                <button
                                  disabled={isLoading}
                                  className="btn btn-warning btn-sm"
                                  onClick={() => editToken(item.id)}
                                  title="edit"
                                >
                                  {isLoading && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                  )}{" "}
                                  <FaRegEdit />
                                </button>
                              )
                              )}
                              {!item.is_paid && item?.patient && (
                                permissions.fee >0 && (
                                <button
                                  className="btn btn-info ms-1 btn-sm"
                                  onClick={() =>
                                    handleShowModal(item.id, item.shift.fees)
                                  }
                                >
                                  Fee
                                </button>
                                )
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {fees != 0 && (
          <FeeModel
            show={showModal}
            handleClose={handleCloseModal}
            tokenid={tokenid}
            fees={fees}
            getPatientList={fatchList}
          />
        )}
      </div>
    </div>
  );
};
