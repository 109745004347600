import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import axios from "axios";
import { AUTH_TOKEN, baseURl } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import Header from "./Header";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

const AddNotice = ({
  show,
  handleSubmit,
  handleInputChange,
  formData,
  modelToggle,
  isLoading,
  today,
  mode,
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="h6">{mode} Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <label className="mb-2">End Date</label>
            <input
              className="form-control"
              onChange={(e) => handleInputChange(e)}
              name="date"
              type="date"
              value={formData.date}
              placeholder="select date"
              min={today}
            />
          </div>

          <div className="mb-4">
            <label className="mb-2">Message</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter Address"
              onChange={(e) => handleInputChange(e)}
              name="message"
              value={formData.message}
            />
          </div>
          <div className="mb-3 text-end">
            <Button variant="secondary" onClick={modelToggle}>
              Close
            </Button>
            <button
              disabled={isLoading}
              className="btn btn-primary ms-2"
              type="submit"
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}{" "}
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export const Notice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [isModel, setIsModel] = useState(false);
  const [mode, setMode] = useState("Add");
  const [formData, setFormData] = useState({
    id: null,
    date: null,
    message: null,
  });
  const [today, setToday] = useState(null);
  const [permissions, setPermissions] = useState({
    add: 0,
    edit: 0,
    delete: 0,
    assign:0
  });
  const userPer = JSON.parse(localStorage.getItem('role_permissions') || "[]");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: formData.id,
      date: formData.date,
      message: formData.message,
    };
    setIsLoading(true);
    axios
      .post(`${baseURl}/doctor/notice/add`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        //console.log("Notice added successfully", response.data.message);
        const setData = {
          id: null,
          date: formData.date,
          message: null,
        };
        setFormData(setData);
        setIsLoading(false);
        fatchList();
        setIsModel(!isModel);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add the token. Please try again.",
        });
        setIsLoading(false);
      });
  };

  const fatchList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/notice/list`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setList(response.data.data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const deleteNotice = async (notice_id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURl}/doctor/notice/delete`,
        { notice_id: notice_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        fatchList();
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const editModel = (item) => {
    const data = {
      id: item.id,
      date: item.date,
      message: item.message,
    };
    setMode("Edit");
    setFormData(data);
    setIsModel(!isModel);
  };
  const modelToggle = () => {
    setMode("Add");
    setIsModel(!isModel);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const setDate = () => {
    var today = new Date();
    var day =
      today.getDate().length === 1 ? "0" + today.getDate() : today.getDate();
    var month =
      (today.getMonth() + 1).length == 0
        ? today.getMonth() + 1
        : "0" + (today.getMonth() + 1);
    var todate = today.getFullYear() + "-" + month + "-" + day;
    setFormData({
      ...formData,
      ["date"]: todate,
    });
    setToday(todate);
  };

  useEffect(() => {
    setDate();
    fatchList();
    if(userPer){
      //console.log(userPer);
      const addPermission = userPer.some((perm) => perm.id === 23);
      const editPermission = userPer.some((perm) => perm.id === 24);
      const deletePermission = userPer.some((perm) => perm.id === 25);
// console.log(addPermission);
      setPermissions({ add: addPermission, edit: editPermission, delete: deletePermission });
  }
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "70px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <div className="main-container my-3 px-lg-3 px-1">
            <div className="d-flex justify-content-center mt-3">
              <div className="card card-body">
                <div className="d-flex justify-content-between">
                  <h4>Notice List</h4>
                  {permissions.add > 0 && (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={modelToggle}
                  >
                    Add Notice
                  </button>
                  )}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>S.N.</th>
                        <th>End Date</th>
                        <th>Message</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item?.date}</td>
                          <td>{item?.message}</td>
                          <td>
                            <div className="d-flex">
                            {permissions.edit > 0 && (
                              <button
                                onClick={() => editModel(item)}
                                className="btn btn-warning btn-sm mx-1"
                                title="edit"
                              >
                                <FaRegEdit />
                              </button>
                            )}
                            {permissions.delete > 0 && (
                              <button
                                onClick={() => deleteNotice(item.id)}
                                className="btn btn-danger btn-sm"
                                title="delete"
                              >
                                <MdDeleteOutline />
                              </button>
                            )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNotice
        show={isModel}
        handleSubmit={handleSubmit}
        formData={formData}
        handleInputChange={handleInputChange}
        modelToggle={modelToggle}
        isLoading={isLoading}
        today={today}
        mode={mode}
      />
    </div>
  );
};
